.pagination {
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(sm) {

    li.page-item {
      display: none;
      margin-left: 0.7rem;
    }

    .page-item:first-child,
    .page-item:nth-child( 2 ),
    .page-item:nth-last-child( 2 ),
    .page-item:last-child,
    .page-item.active,
    .page-item.disabled {
      display: inline-block;
    }
  };

}
